@if (displayMode() === 'loading') {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<mat-card  appHeaderReset
           appState
           [title]="'Account'"
           [isSubPage]="true">

  <div class="card-header">
    <mat-card-header class="card-title-block">
      <mat-card-title>
        Account
        @if (displayMode() === 'loading') {
          ...
        } @else {
          {{model$().displayName }}
        }

      </mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    @if(!appState.isMobile$()) {
      <div class="card-actions-top-block">
        <top-card-actions></top-card-actions>
      </div>
    }
  </div>

  <mat-card-content class="mat-body-1">

    <form id="form" [formGroup]="form" class="detail-form" (ngSubmit)="onOk()">

      <div style="display:none;">
        <input id="id" formControlName="id">
        <input id="rev" formControlName="rev">
      </div>

      <!-- region name -->
      <div class="form-row">
        <label class="label-column" for="firstName">
          <p class="body-1">Naam</p>
        </label>

        <div class="input-column">
          <form-display-mode [displayMode]="displayMode()">
            <p class="display">{{model$().displayName }}</p>
            <div class="multi-input">
              <mat-form-field class="firstName-input">
                <mat-label>Voornaam</mat-label>
                <input id="firstName" matInput formControlName="firstName">
              </mat-form-field>

              <mat-form-field class="infix-input">
                <mat-label>Tussenv.</mat-label>
                <input matInput formControlName="infix">
              </mat-form-field>

              <mat-form-field class="surname-input">
                <mat-label>Achternaam</mat-label>
                <input matInput formControlName="surname">
              </mat-form-field>
            </div>
          </form-display-mode>
        </div>
      </div>
      <!-- endregion -->

      <!-- region educationLevelId -->
      <div class="form-row">
        <label class="label-column" for="educationLevelId">
          <p>Bedrijf</p>
        </label>

        <div class="input-column">
          <form-display-mode [editable]="false" [displayMode]="displayMode()">
            <autocomplete-input
              class="display"
              id="educationLevelId"
              formControlName="companyId"
              [displayOnly]="true"
              [source]="companySource"
              [filterField]="'companyId'"
              [placeholder]="'Bedrijf'"
            ></autocomplete-input>
          </form-display-mode>
        </div>
      </div>
      <!-- endregion -->

      <!-- region email -->
      <div class="form-row">
        <label class="label-column" for="firstName">
          <p>Email</p>
        </label>

        <div class="input-column">
          <form-display-mode [editable]="false" [displayMode]="displayMode()">
            <p class="display">{{model$().email }}</p>
          </form-display-mode>
        </div>
      </div>
      <!-- endregion -->

      <!-- region authRole -->
      <div class="form-row">
        <label class="label-column">
          <p>Rol</p>
        </label>

        <div class="input-column">
          <form-display-mode [editable]="false" [displayMode]="displayMode()">
            <p class="display">{{model$().authRole?.displayName }}</p>
          </form-display-mode>
        </div>
      </div>
      <!-- endregion -->

    </form>

  </mat-card-content>

  <mat-card-actions>

    <div class="left">
<!--      <button mat-flat-button (click)="requestPassword()">Reset wachtwoord</button>-->
<!--      <button mat-flat-button>Verander email</button>-->
    </div>

    <div class="right">
      @if ((displayMode() == 'display' && form.dirty) || (displayMode() == 'edit' && form.dirty)) {
        <button mat-flat-button color="primary" type="submit" form="form" autofocus>Ok</button>
        <button mat-flat-button (click)="onCancel()">Annuleer</button>
      } @else {
        <button mat-flat-button color="primary" (click)="close()">Sluit</button>
      }
    </div>

  </mat-card-actions>
</mat-card>
